import classnames from 'classnames'
import { Navbar, Badge, Row, Col, Button } from 'react-bootstrap'
import { formatNumber, getTotalOdds, getBetWinnings } from '../../utils'
import ColAuto from '../utils/ColAuto'
import Icon from '../../components/utils/Icon'

export default function TicketResumeBar({ isDarkTheme, settings, amount = 0, odds, onBetClick }) {
  const totalOdds = getTotalOdds(odds, settings)
  const winnings = getBetWinnings(amount, totalOdds, settings)

  return (
    <Navbar fixed="bottom" expand={false} className={classnames({ 'bg-black': isDarkTheme, 'bg-white': !isDarkTheme })} style={{ maxHeight: '54px', textAlign: 'center' }} onClick={onBetClick}>
      <div className="ticket-content w-100">
        <Row>
          <Col>
            <div className="d-flex flex-column text-left">
              <div className={classnames({ 'text-white': isDarkTheme })}><small>Valor</small></div>
              <div className="text-theme-color">{formatNumber(amount)}</div>
            </div>
          </Col>
          <ColAuto>
            <div className="mt-1">
              <Button block variant="primary">
                  {odds.length > 0 ?
                  <Badge variant={isDarkTheme ? 'dark' : 'light'} className="align-top">{odds.length}</Badge>
                  : <Icon name="receipt" />
                  }
              </Button>
            </div>
          </ColAuto>
          <Col>
            <div className="d-flex flex-column text-right">
              <div className={classnames({ 'text-white': isDarkTheme })}><small>Prêmio</small></div>
              <div className="text-theme-color">{formatNumber(winnings)}</div>
            </div>
          </Col>
        </Row>
      </div>
    </Navbar>
  )
}
